import css from 'styled-jsx/css';
import typography from '../../../../styles/typography';

export const styles = css`
    .QuantityBoxTooltip {
      ${typography.h9};
      background: var(--colors-nightRider);
      color: white;
      line-height: 18px;
      font-size: 12px;
      text-align: left;
      pointer-events: auto;
      white-space: pre-line;
      border-radius: 4px;
      display: none;

      padding: 4px 8px;
      z-index: 10;
      position: relative;
      max-width: 256px;
    }

    .QuantityBoxTooltip[data-show] {
      display: block;
    }

    .QuantityBoxTooltip__arrow,
    .QuantityBoxTooltip__arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      z-index: -1;
    }

    .QuantityBoxTooltip__arrow::before {
      content: "";
      transform: rotate(45deg);
      background: #333;
    }

    #tooltip[data-popper-placement^="top"] > .QuantityBoxTooltip__arrow {
      bottom: -4px;
    }

    #tooltip[data-popper-placement^="bottom"] > .QuantityBoxTooltip__arrow {
      top: -4px;
    }

    #tooltip[data-popper-placement^="left"] > .QuantityBoxTooltip__arrow {
      right: -4px;
    }

    #tooltip[data-popper-placement^="right"] > .QuantityBoxTooltip__arrow {
      left: -4px;
    }

  `;
