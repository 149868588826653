import { smWidth, tabletWidth } from '../../../../styles/media';
import { addToCartButtonsEnum } from '../../../../utils/marketing/enhancedEcommerce';

export type PaddingType = { top?: number, bottom?: number } | 0;
export type getElementBoundaryType = (place: string) => Element;
export type getOverflowPaddingType = (boundary: Element) => PaddingType;
export type PlacementType = 'top' | 'bottom';
export type getPlacementType =
  (ref: { current: Element }, boundary: Element) => PlacementType;

export const customTooltipPaddingAndPlaceSettings = [
  'PageWrapBody_desktopMode',
  'PageWrapBody_phoneTabletMode',
  'BigProductCard',
  'BigProductCard__modal',
];

export const getElementBoundary: getElementBoundaryType = (place) => {

  if (place?.includes(addToCartButtonsEnum.PRODUCT_CARD)) {
    const productModal = document.getElementById('BigProductCard__modal');
    const productPage = document.getElementById('BigProductCard');
    return productModal || productPage;
  }

  if (
    (place === addToCartButtonsEnum.CHECKOUT) ||
    (place === addToCartButtonsEnum.CART)
  ) {
    return document.getElementById('CartPopup__products');
  }

  /** @todo '.Responsive__' classes use may need refactoring it when Responsive component is changed */
  if (place === addToCartButtonsEnum.SEARCH_BOX) {
    if (window.innerWidth >= smWidth) {
      return document.querySelector('.Responsive_desktop #SearchBox__results');
    }
    return document.querySelector('.Responsive_phoneTablet #SearchBox__results');
  }

  if (window.innerWidth >= smWidth) {
    return document.querySelector('#PageWrapBody_desktopMode');
  }
  return document.querySelector('#PageWrapBody_phoneTabletMode');

};

export const getOverflowPadding: getOverflowPaddingType = boundary => {

  if ( boundary && customTooltipPaddingAndPlaceSettings.includes(boundary.id)) {
    const header = document.getElementById('Header');
    if (!header) {
      return 0;
    }

    if (window.innerWidth >= smWidth) {
      if (boundary.id === 'BigProductCard__modal') {
        return 0;
      }
      return { top: header.offsetHeight };
    }
    if (window.innerWidth < smWidth && window.innerWidth >= tabletWidth) {
      if (boundary.id === 'BigProductCard__modal') {
        return 0;
      }
      return { top: header.offsetHeight };
    }

    const bottomAddButtonsHeight = document.getElementById(
      'BigProductCardTopInfo__addButtons',
    )?.offsetHeight;

    if (boundary.id === 'BigProductCard__modal') {
      return { bottom: bottomAddButtonsHeight };
    }

    return boundary.id === 'BigProductCard'
      ? { bottom: bottomAddButtonsHeight, top: header.offsetHeight }
      : { top: header.offsetHeight };
  }

  return 0;
};

export const getPlacement: getPlacementType = (ref, boundary) => {

  if (!customTooltipPaddingAndPlaceSettings.includes(boundary?.id)) {
    return 'top';
  }

  let placementTopPadding: number;
  if (window.innerWidth >= smWidth) {
    placementTopPadding = 210;
  } else if (window.innerWidth < smWidth && window.innerWidth >= tabletWidth) {
    placementTopPadding = 165;
  } else if (boundary?.id === 'BigProductCard') {
    placementTopPadding = 150;
  } else {
    placementTopPadding = 50;
  }

  const rect = ref?.current?.getBoundingClientRect();
  const placementIsTop = rect.y - placementTopPadding > 0;

  return placementIsTop ? 'top' : 'bottom';

};
