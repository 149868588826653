import { createPopper } from "@popperjs/core";
import { FC, Fragment, memo, RefObject, useCallback, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { getElementBoundaryType, getOverflowPaddingType, getPlacementType } from './helpers';
import { styles } from './QuantityBoxTooltip.styles';

interface Props {
  reactRef: RefObject<HTMLInputElement>;
  text: string;
  settings: {
    boundary: ReturnType<getElementBoundaryType>,
    placement: ReturnType<getPlacementType>,
    overflowPadding: ReturnType<getOverflowPaddingType>,
  }
}

const QuantityBoxTooltip: FC<Props> = (props) => {
  const {
    reactRef,
    text,
    settings,
  } = props;
  const {
    boundary,
    placement,
    overflowPadding,
  } = settings;
  const tooltip = useRef(null);
  const popperInstance = useRef(null);

  const createTooltip = useCallback(() => {
    popperInstance.current = createPopper(
      reactRef.current,
      tooltip.current, {
      placement: placement || 'top',
      modifiers: [
        {
          name: "offset",
          options: { offset: [0, 10] },
        },
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            boundary: boundary,
            altBoundary: true,
            rootBoundary: 'viewport',
            padding: overflowPadding || 0,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom'],
            boundary: boundary,
            rootBoundary: 'viewport',
            allowedAutoPlacements: ['top', 'bottom'],
          },
        },
      ],
    });
  }, [
    boundary,
    reactRef,
    overflowPadding,
    placement,
  ]);

  const destroyTooltip = () => {
    if (popperInstance.current) {
      popperInstance.current.destroy();
      popperInstance.current = null;
    }
  };

  const showPopper = useCallback(() => {
    if (tooltip.current) {
      tooltip.current.setAttribute("data-show", "shown");
      createTooltip();
    }
  }, [createTooltip]);

  const hidePopper = useCallback(() => {
    if (tooltip.current) {
      tooltip.current.removeAttribute("data-show");
      destroyTooltip();
    }
  }, []);

  const handleTooltipClick = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    showPopper();
    return () => {
      hidePopper();
    };
  }, [showPopper, hidePopper]);

  if (!boundary) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <Fragment>
        <div
          ref={tooltip}
          id="tooltip"
          role="tooltip"
          className='QuantityBoxTooltip'
          onClick={handleTooltipClick}
          data-marker='Product Amount Tooltip'
          data-testid='QuantityBoxTooltip'
        >
          {text}
          <div
            id="arrow"
            className='QuantityBoxTooltip__arrow'
            data-popper-arrow
          />
        </div>
        <style jsx>{styles}</style>
      </Fragment>,
      boundary,
    )
  );
};

export default memo(QuantityBoxTooltip);
