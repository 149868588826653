// It used to ability select text into input
// element (of NumberFormat) when it inside tag 'a'
const fixDraggableNumberFormat = (input?: HTMLElement) => {
  if (input) {
    input.ondragstart = e => {
      e.preventDefault();
      e.stopPropagation();
    };
  }
};

export default fixDraggableNumberFormat;
