/** @info credits to https://css-tricks.com/snippets/javascript/move-cursor-to-end-of-input/ */

const moveInputCursor = (el, focusPosition) => {
  if (!el) { return; }

  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = focusPosition || el.value.length;
  } else if (typeof el.createTextRange != "undefined") {
    el.focus();
    const range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
};

export default moveInputCursor;
