import cn from 'classnames';
import Cookies from 'js-cookie';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import useLocalization from 'src/services/localization/useLocalization';
import { weightMeasureEnum } from '../../../fixtures';
import { ProductUnit, Quantity } from '../../../redux/apiTypes';
import { MEASURE_KG, MEASURE_PCS } from '../../../redux/constants';
import { lg_md } from '../../../styles/media';
import { restrict } from '../../../utils/cart/restrict';
import { diff, sum } from '../../../utils/js-helpers/numsOperations';
import fixDraggableNumberFormat from '../../../utils/product/fixDraggableNumberFormat';
import { getConvertedAmount } from '../../../utils/product/getConvertedAmount';
import { getIsServer } from '../../../utils/system/getIsServer';
import moveInputCursor from '../../../utils/system/moveInputCursor';
import { getNumberInputResolve, styles } from './QuantityBox.styles';
import QuantityBoxTooltip from './QuantityBoxTooltip/QuantityBoxTooltip';
import { getElementBoundary, getElementBoundaryType, getOverflowPadding, getOverflowPaddingType, getPlacement, getPlacementType } from './QuantityBoxTooltip/helpers';

/**
 * @todo this is a temporary hack - better to detect touch instead
 * @copyright Eugene Garmash (March 10th, 2021 11:59 AM)
 */
export const WIDE_TABLET_WIDTH = 1025;

export interface QuantityBoxProps {
  amount: number | undefined
  unit: ProductUnit
  quantity: Quantity
  className?: string
  boxSmall?: boolean
  mobileExtend?: boolean
  suffix: string
  wide?: boolean
  low?: boolean
  inputMargin?: string
  place: string
  tooltipIsInHiddenMode?: boolean
  initialRenderWithAutofocus?: boolean
  onChangeAmount(amount: number): void
  onSetLocalTintingMode?(isOn: boolean): void
  onEnterPressed?(): void
}

type ActionButtonType = 'buttonRemove' | 'buttonMinus' | 'buttonPlus';

export interface tooltipState {
  isShown: boolean,
  boundary?: ReturnType<getElementBoundaryType>,
  placement?: ReturnType<getPlacementType>,
  overflowPadding?: ReturnType<getOverflowPaddingType>,
}

const QuantityBox = (props: QuantityBoxProps) => {
  const {
    amount,
    onChangeAmount,
    unit,
    quantity,
    className,
    boxSmall,
    mobileExtend,
    suffix,
    wide,
    low,
    inputMargin,
    onSetLocalTintingMode,
    place,
    tooltipIsInHiddenMode,
    initialRenderWithAutofocus,
    onEnterPressed,
  } = props;
  const localize = useLocalization();
  const isDesktop = useMediaQuery({query: lg_md});
  const autofocusIsEnabled =
    !getIsServer() &&
    isDesktop &&
    initialRenderWithAutofocus;

  const step = quantity?.step || weightMeasureEnum.properties[unit].STEP;
  const convertedAmount = getConvertedAmount(amount, unit, true);

  const inputElemRef = useRef<HTMLInputElement>(null);
  const focusTimerRef = useRef(null);
  const clickTimeoutRef = useRef(null);
  const clickedButtonsRef = useRef(null);

  const [tooltipState, setTooltipState] = useState({ isShown: false } as tooltipState );
  const [plusClickCounter, setPlusClickCounter] = useState(0);
  const [minusClickCounter, setMinusClickCounter] = useState(0);

  const {
    className: inputClassName,
    styles: inputStyles,
  } = getNumberInputResolve(wide, low, inputMargin);

  const [value, setValue] = useState(convertedAmount);

  const handleCustomFocus = useCallback(() => {
    /** @todo It's better to get media from React context */
    const appIsInDesktopMode = window.innerWidth >= WIDE_TABLET_WIDTH;
    if(appIsInDesktopMode) {
      if (focusTimerRef.current === 'canceled') { return; }
      inputElemRef.current.focus();
      if (!Number.isInteger(convertedAmount)) {
        focusTimerRef.current = setTimeout(() => {
          const focusPosition = String(convertedAmount).length;
          moveInputCursor(inputElemRef.current, focusPosition);
        }, 100);
        return;
      }
    }
  }, [convertedAmount]);

  useEffect(() => {
    setValue(convertedAmount);
  }, [convertedAmount]);

  useEffect(() => {
    /**
     * @info convertedAmount changes handleCustomFocus,
     * which makes this useEffect work with proper
     * convertedAmount value in a handleCustomFocus function call.
     * Another way of focusing the input (takes 300ms) would be
     * handleCustomFocus() with getConvertedAmount(newAmount, unit, true)
     * passed as a arg to use instead of convertedAmount;
     *
     * The problem is that it is hard to focus the input
     * properly when "plus" is clicked as it rerenders the value due to
     * useEffect[convertedAmount] and the way rendering
     * in NumberFormat works
     * */
    if (clickedButtonsRef.current) {
      handleCustomFocus();
      clickedButtonsRef.current = false;
    }
  }, [handleCustomFocus]);

  useEffect(() => {
    return () => {
      clearTimeout(focusTimerRef.current);
      clearTimeout(clickTimeoutRef.current);
    };
  }, [] );

  const validateInput = (values: NumberFormatValues) => {
    const { patterns } = weightMeasureEnum;

    if (unit === MEASURE_PCS) {
      return patterns[MEASURE_PCS].test(values.value);
    }

    if (unit === MEASURE_KG) {
      return patterns[MEASURE_KG].test(values.value);
    }
  };

  const handleBlur = () => {
    const newAmount = getConvertedAmount(value, unit);

    /**
     * @info when we change input value and then
     * quickly press 'Enter' we blur the input and
     * might focus it again because of
     * focus() in setTimeout which is fired with some delay.
     * The solution is to save 'canceled' value into
     * focusTimerRef.current and check it when
     * firing handleCustomFocus()
     * */
    clearTimeout(focusTimerRef.current);
    focusTimerRef.current = 'canceled';

    if (!newAmount && newAmount !== 0) {
      setValue(convertedAmount);
      return;
    }

    if (newAmount === 0) {
      setValue(convertedAmount);
      onChangeAmount(0);
      return;
    }

    if (newAmount === amount) {
      return;
    }

    const limitedAmount = restrict(newAmount, unit, quantity);
    setValue(getConvertedAmount(limitedAmount, unit, true));
    onChangeAmount(limitedAmount);
  };

  const handleInputKeyDown = event => {
    if(event.key === 'Enter') {
      event.target.blur();
      if (onEnterPressed) {
        onEnterPressed();
      }
    } else if (
      tooltipState.isShown &&
      window.innerWidth >= WIDE_TABLET_WIDTH
    ) {
      setTooltipState({ isShown: false });
    }
  };

  const handleMouseDown= event => {
    event.stopPropagation();
  };

  const handleClickAmountButton = () => {
    focusTimerRef.current = null;
    clickedButtonsRef.current = true;
  };

  const handleButtonClick = (buttonType: ActionButtonType) => () => {
    const convertedValueToAmount = getConvertedAmount(value, unit);

    if (buttonType === 'buttonRemove') {
      onChangeAmount(0);
    }
    else if (buttonType === 'buttonMinus') {
      const newAmount = value === undefined ? diff(amount, step) : diff(convertedValueToAmount, step);
      onChangeAmount(newAmount);
      setPlusClickCounter(plusClickCounter + 1);
      handleClickAmountButton();
    }
    else if (buttonType === 'buttonPlus') {
      const newAmount = value === undefined ? sum(amount, step) : sum(convertedValueToAmount, step);
      const limitedAmount = restrict(newAmount, unit, quantity);
      onChangeAmount(limitedAmount);
      setMinusClickCounter(minusClickCounter + 1);
      handleClickAmountButton();
    }
  };

  useEffect(() => {
    if (
      !Cookies.get('product-tooltip') &&
      (minusClickCounter === 5 || plusClickCounter === 5)
    ) {
      const boundary = getElementBoundary(place);
      const placement = getPlacement(inputElemRef, boundary);
      const overflowPadding = getOverflowPadding(boundary);

      setTooltipState({
        isShown: true,
        boundary,
        placement,
        overflowPadding,
      });
      onSetLocalTintingMode && onSetLocalTintingMode(false);
      clickTimeoutRef.current = setTimeout(
        () => {
          setTooltipState({ isShown: false });
          Cookies.set(
            'product-tooltip',
            'shown',
            { expires: 1 },
          );
          onSetLocalTintingMode && onSetLocalTintingMode(true);
        },
        4000,
      );
    }
  }, [
    plusClickCounter,
    minusClickCounter,
    onSetLocalTintingMode,
    place,
  ]);

  const handleInputFocus = () => {
    if (
      window.innerWidth < WIDE_TABLET_WIDTH &&
      tooltipState.isShown
    ) {
      setTooltipState({ isShown: false });
    }
  };

  const handleMouseEvent: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const showDeleteButton = value === undefined || getConvertedAmount(value, unit) <= step;

  return (
    <div
      className={cn(
        'QuantityBox', {
        'QuantityBox_small': boxSmall,
        'QuantityBox_mobileExtend': mobileExtend,
        [className]: !!className,
      })}
      role='button'
      tabIndex={0}
      onClick={handleMouseEvent}
      draggable={true}
      ref={fixDraggableNumberFormat}
      onMouseDown={handleMouseEvent}
      data-tooltiped={tooltipState.isShown && !tooltipIsInHiddenMode}
    >
      {(showDeleteButton) ? (
        <button
          type='button'
          onClick={handleButtonClick('buttonRemove')}
          className='QuantityBox__button QuantityBox__button_minus'
          data-testid='buttonRemove'
          data-marker='Remove'
        >
          <i className='QuantityBox__deleteButton icon-delete' />
        </button>
      ) : (
        <button
          type='button'
          onClick={handleButtonClick('buttonMinus')}
          className='QuantityBox__button QuantityBox__button_minus'
          data-testid='buttonMinus'
          data-marker='-'
        >
          <svg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='8' y='0.25' width='1.5' height='8' transform='rotate(90 8 0.25)' fill='#404844'/>
          </svg>
        </button>
      )}
      { tooltipState.isShown && !tooltipIsInHiddenMode &&
          <QuantityBoxTooltip
            reactRef={inputElemRef}
            text={localize('productQuantity_tooltip')}
            settings={{
              boundary: tooltipState.boundary,
              placement: tooltipState.placement,
              overflowPadding: tooltipState.overflowPadding,
            }}
          />
      }
      <NumberFormat
        suffix={suffix && ' ' + suffix}
        value={value}
        className={inputClassName}
        onValueChange={valuesObject => setValue(valuesObject.floatValue)}
        onBlur={handleBlur}
        spellCheck={false}
        allowNegative={false}
        onKeyDown={handleInputKeyDown}
        isAllowed={validateInput}
        allowedDecimalSeparators={['.', ',']}
        onMouseDown={handleMouseDown}
        decimalSeparator='.'
        fixedDecimalScale={unit === MEASURE_KG}
        inputMode='decimal'
        data-testid='amountInput'
        data-marker='Amount'
        autoFocus={autofocusIsEnabled}
        onFocus={handleInputFocus}
        getInputRef={inputElemRef}
      />
      {inputStyles}
      <button
        type='button'
        onClick={handleButtonClick('buttonPlus')}
        className='QuantityBox__button QuantityBox__button_plus'
        data-testid='buttonPlus'
        data-marker='+'
      >
        <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='4.25' y='0.25' width='1.5' height='9.5' fill='white' />
          <rect x='9.75' y='4.25' width='1.5' height='9.5' transform='rotate(90 9.75 4.25)' fill='white'/>
        </svg>
      </button>

      <style jsx>{styles}</style>
    </div>
  );
};

export default QuantityBox;
